import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  defaultLocale: 'pl',
  locales: [
    {
      code: 'pl',
      domain: '',
      file: 'index.js',
      iso: 'pl-PL',
    },
  ],
  ...config,
})
