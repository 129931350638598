export default {
  footer: {
    certificates: [
      {
        image: {
          url: '/images/footer/sukl.png',
          alt: 'Krajowy Rejestr Aptek',
          width: 120,
          height: 100,
        },
        link: 'https://rejestrymedyczne.ezdrowie.gov.pl/ra/pharmacy/public/details/1219675',
      },
    ],
    links: [
      {
        url: '/kim-jestesmy',
        text: 'O nas',
      },
      {
        url: '/faq',
        text: 'Pomoc',
      },
      {
        url: '/bezpieczne-zakupy',
        text: 'Bezpieczeństwo',
      },
    ],
    legalText: 'Wszystkie ceny podawane w serwisie na stronie https://www.drmax.pl/ mają charakter ceny maksymalnej w wypadku złożenia rezerwacji w tym serwisie. Każda Apteka może określić własną cenę odsprzedaży dla danego produktu zarezerwowanego poprzez serwis https://www.drmax.pl/ nie wyższą niż cena maksymalna podana w serwisie.',
  },
}
