import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: '7eace882234e5220b8f5d8f853e7f31d',
  analyticsApiKey: '0f73e196836d58d94efba1bd4b279cf9',
  ...config,
})
