export default {
  seo: {
    description: 'Platforma rezerwacyjna Dr.Max',
    noIndexUrls: [
      'enable-cookies',
      'privacy-policy-cookie-restriction-mode',
      'service-unavailable',
      'private-sales',
      'reward-points',
      'amasty-faq-home-page',
      'cookies',
      'przetwarzanie-danych-osobowych',
    ],
    siteName: 'Platforma rezerwacyjna Dr.Max',
    title: 'drmax.pl',
    image: '/images/logo.png',
  },
}
