export default {
  footer: {
    footerTopLogos: [],
    emailSubscription: false,
    paymentsImages: [
      {
        src: '/images/no-responsive-images/payments_pl.svg',
        alt: 'Akceptujemy płatności: mastercard, visa, payu, dpd',
        width: 350,
        height: 40,
      },
    ],
    suklImages: false,
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: true,
    footerCertificateText: 'Wszystkie ceny podawane w serwisie na stronie https://www.drmax.pl/ mają charakter ceny maksymalnej w wypadku złożenia rezerwacji w tym serwisie. Każda Apteka może określić własną cenę odsprzedaży dla danego produktu zarezerwowanego poprzez serwis https://www.drmax.pl/ nie wyższą niż cena maksymalna podana w serwisie.',
    footerCertificateTextClass: 'alert alert-info',
    footerCertificateImages: [],
    flags: false,
    mobileApplications: true,
  },
}
